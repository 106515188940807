@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

/* html, body {
  overflow: hidden;
} */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
}

.home,
.reports,
.products,
.team,
.reports {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}
