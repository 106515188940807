@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700&display=swap);
/* html, body {
  overflow: hidden;
} */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
}

.home,
.reports,
.products,
.team,
.reports {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

:root {
  --text-color: #000000;
  --text-secondary-color: #363636;
  
  --background-color: #FFFFFF;

  --font-family: 'Montserrat', Verdana, sans-serif ;
}

html, body {
  padding: 0;
  margin: 0;
  font-family: var(--font-family);
  background-color: var(--background-color);
  color: var(--text-color);
  overscroll-behavior-x: contain;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  color: inherit;
  font-family: inherit;
  border-collapse: collapse;
}

.numberInput {
  color: var(--text-secondary-color);
}

.numberInput::-webkit-outer-spin-button, .numberInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.numberInput::-webkit-input-placeholder {
  color: #BBBBBB;
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
}

* {
  /* overscroll-behavior: contain;s */
  -ms-webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;

  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}

*::-webkit-scrollbar {
  display: none;
}

.datatable-sort-icon {
  display: flex;
  flex-direction: column;
  
}

.rdt_TableCol_Sortable > span{
        margin-left: auto;
}

.datatable-sort-icon span {
  font-size: 11px;
}

.icon-up:before {
  content: "\25B2";
}
.icon-down:before {
  content: "\25BC";
}

.dWnguF .asc .datatable-sort-icon #iconUp {
  color: #000000 !important;
}
.dWnguF .desc .datatable-sort-icon #iconUp {
  color: #000000 !important;
}
.dWnguF .asc .datatable-sort-icon #iconDown {
  color: #000000 !important;
}
.dWnguF .desc .datatable-sort-icon #iconDown {
  color: #000000 !important;
}

.ant-tree-draggable-icon {
    display: none !important;
}

.ant-tree-switcher-noop {
    display: none !important;
}

.ant-tree-title {
    border: 1px solid #000000;
    background: none;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 4px;
    white-space: nowrap;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #000000;

}

.ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: rgba(32, 125, 234, .3) !important;
}
.ant-tree-title {
    border: none;
    display: flex;
    padding: 0;
}

.ant-tree .ant-tree-node-content-wrapper {
    padding: 0;
}

.tree-node-container:hover {
    background-color: #ebebea;
}

.tree-node-button {
    display: none;
    background: none;
}

.tree-node-button:hover {
    background: #dddddc;
}

.tree-node-container:hover .tree-node-button {
    display: flex;
}

.ant-tree-switcher, .ant-tree-switcher_close {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.my-modal {
    width: 98% !important;
    max-width: none !important;
    height: 80% !important;
    max-height: none !important;
}
