.ant-tree-title {
    border: none;
    display: flex;
    padding: 0;
}

.ant-tree .ant-tree-node-content-wrapper {
    padding: 0;
}

.tree-node-container:hover {
    background-color: #ebebea;
}

.tree-node-button {
    display: none;
    background: none;
}

.tree-node-button:hover {
    background: #dddddc;
}

.tree-node-container:hover .tree-node-button {
    display: flex;
}

.ant-tree-switcher, .ant-tree-switcher_close {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.my-modal {
    width: 98% !important;
    max-width: none !important;
    height: 80% !important;
    max-height: none !important;
}