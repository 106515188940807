.datatable-sort-icon {
  display: flex;
  flex-direction: column;
  
}

.rdt_TableCol_Sortable > span{
        margin-left: auto;
}

.datatable-sort-icon span {
  font-size: 11px;
}

.icon-up:before {
  content: "\25B2";
}
.icon-down:before {
  content: "\25BC";
}

.dWnguF .asc .datatable-sort-icon #iconUp {
  color: #000000 !important;
}
.dWnguF .desc .datatable-sort-icon #iconUp {
  color: #000000 !important;
}
.dWnguF .asc .datatable-sort-icon #iconDown {
  color: #000000 !important;
}
.dWnguF .desc .datatable-sort-icon #iconDown {
  color: #000000 !important;
}
