.ant-tree-draggable-icon {
    display: none !important;
}

.ant-tree-switcher-noop {
    display: none !important;
}

.ant-tree-title {
    border: 1px solid #000000;
    background: none;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 4px;
    white-space: nowrap;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #000000;

}

.ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: rgba(32, 125, 234, .3) !important;
}